import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment'
import Button from '@mui/joy/Button';
import AtmIcon from '@mui/icons-material/Atm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AppleIcon from '@mui/icons-material/Apple';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';

const userEmailIds = ['ali.akbar@ebtida.com', 'fahd@ebtida.com', 'hesham@ebtida.com', 'salman@ebtida.com', 'faiz@ebtida.com', 'hamza@ebtida.com', 'jauhar@ebtida.com', 'anas@ebtida.com']

function OrderItemsList({ ordersData, updatestatesresults, updatestates }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  function tableBgColor(state, sstate, ostate) {
    if (ostate === 'cancelled') return '#fcf8f8'
    if (ostate === 'fraud') return '#fcf8f8'
    if (state === 'cancelled') return '#fcf8f8'
    if (sstate === 'processing') {
      return '#f6f8f8'
    } else if (sstate === 'delivered') {
      return '#f6f8f5'
    } else {
      return '#fbf9f3'
    }
  }

  function getThisStatus(state, sstate, ostate) {
    if (ostate === 'cancelled') return <span className="badge badge-danger">Canceled</span>
    if (ostate === 'fraud') return <span className="badge badge-danger">Fraud</span>
    if (state === 'cancelled') return <span className="badge badge-danger">Canceled</span>
    if (sstate === 'processing') {
      return <span className="badge badge-info" style={{ textTransform: 'capitalize' }}>{sstate}</span>
    } else if (sstate === 'delivered') {
      return <span className="badge badge-success" style={{ textTransform: 'capitalize' }}>{sstate}</span>
    } else {
      return <span className="badge badge-warning" style={{ textTransform: 'capitalize' }}>{sstate}</span>
    }
  }


  function getPaymentTypeIcon(type) {
    if (type === 'card') {
      return <CreditCardIcon style={{ fontSize: '15px' }} />
    } else if (type === 'apple_pay') {
      return <AppleIcon style={{ fontSize: '15px' }} />
    } else if (type === 'payby_apple') {
      return <AppleIcon style={{ fontSize: '15px' }} />
    } else if (type === 'bank_transfer') {
      return <AtmIcon style={{ fontSize: '15px' }} />
    } else if (type === 'tap') {
      return <img src='Logo_7de102aab6.svg' style={{ width: '20px' }} />
    } else if (type === 'tap_tabby') {
      return <img src='tabby-image.png' style={{ width: '20px' }} />
    } else {
      return ''
    }
  }

  function getUserEmailState(data) {
    const hostName = data?.users?.split(",");
    return hostName?.includes(data?.email) ? true : false;
  }

  function isDelayed(create, days) {
    let today = moment().format()
    const exist = moment(create).add(Number(days), 'days').format()
    if (today > exist) {
      return true
    } else {
      return false
    }
  }

  function isDelayedStatus(data, state) {
    data = data.trim();
    const values = data.split(',');
    const thisstatus = values[values?.length - 1].trim();
    if (thisstatus === 'delivered') {
      return false
    } else if (thisstatus === 'cancel') {
      return false
    } else if (thisstatus === 'cancelled') {
      return false
    } else if (state === 'cancelled') {
      return false
    } else {
      return true
    }
  }


  function getMessageCount(messages, message) {
    if (messages?.length > 0) {
      return true
    } else if (message?.length > 0) {
      return true
    } else {
      return false
    }
  }


  const ComponentToPrintWrapper = ({ item }) => {
    return (
      <tr style={{ position: 'relative', background: tableBgColor(item.state, item.shipping_state, item.order?.states) }}>
        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Dropdown>
              <MenuButton
                variant='outlined'
                size='sm'
                style={{ borderRadius: '4px', minHeight: '18px', maxHeight: '18px', padding: '0px', marginRight: '5px' }}
                className="Header-button-connect">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowDropDownIcon style={{ fontSize: '1.2rem' }} />
                </div>
              </MenuButton>
              <Menu>
                {userEmailIds?.map((email, i) =>
                  <MenuItem key={i} className='orders-sub-side-menu' onClick={(() =>
                    updatestates({
                      id: item.order?.id,
                      domain: item.order?.domain,
                      email: email,
                      users: true,
                      userId: userId,
                      userToken: userToken,
                      admin: admin,
                    }))}>
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                      <input style={{ margin: '0px 5px' }} type='checkbox' checked={getUserEmailState({ users: item.order?.users, email: email }) ? true : false} />
                      <div style={{ textAlign: 'left' }}>{email}</div>
                    </div>
                  </MenuItem>
                )}
              </Menu>
            </Dropdown>

            {item.shipping_status?.split(',')?.includes('dispatched') ?
              <div className='badge badge-dark' style={{ marginRight: '5px' }}>procured</div> :
              <Button
                variant='outlined'
                disabled={updatestatesresults?.isLoading && updatestatesresults?.originalArgs?.id === item.id}
                onClick={(() => updatestates({
                  domain: item.order?.domain,
                  id: item.id,
                  state: 'dispatched',
                  email: item.order?.users,
                  local: true,
                  userId: userId,
                  userToken: userToken,
                  admin: admin,
                  info: 'local'
                }))}
                style={{
                  textTransform: 'capitalize',
                  fontSize: '10px',
                  fontWeight: 400,
                  minHeight: '15px',
                  padding: '0px 6px',
                  marginRight: '5px',
                  borderRadius: '4px'
                }}>

                <span>
                  {updatestatesresults?.isLoading && (updatestatesresults?.originalArgs?.info === 'local' && updatestatesresults?.originalArgs?.id === item.id) ?
                    <CircularProgress style={{ fontSize: '12px', width: '8px', height: '8px', color: '#fff' }} />
                    :
                    <>
                      Procure
                    </>
                  }
                </span>
              </Button>
            }

            {isDelayedStatus(item.shipping_status, item.state) &&
              <>
                {isDelayed(item.createdAt, item.estimated_delivery) &&
                  <span className='badge badge-warning' style={{ marginRight: '5px' }}>Delayed</span>
                }
              </>
            }

            {getMessageCount(item.order?.messages, item.order?.message) &&
              <div style={{ position: 'relative', marginRight: '5px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-chat-square-dots" viewBox="0 0 16 16">
                  <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>
                <span
                  className='badge badge-success'
                  style={{
                    position: 'absolute',
                    top: '-7px',
                    right: '-5px',
                    lineHeight: '12px',
                    borderRadius: '100px',
                    width: '12px',
                    height: '12px',
                    textAlign: 'center',
                    fontSize: '10px',
                    fontWeight: 400,
                    padding: '0px'
                  }}
                >{item.order?.messages ? Number(item.order?.messages?.length) + Number(item.order?.message?.length) : item.order?.message?.length}</span>
              </div>
            }
          </div>
        </td>
        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link style={{ color: !item.order?.status ? 'tomato' : '#5a5858' }} to={`/order/${item.order?.order_number}`}>
              {item.order?.order_number}
            </Link>
            {item.awb_number && <img title='AWB number generated' src='/awb-new.jpeg' style={{ width: '30px', margin: '0px 6px' }} />}
          </div>
        </td>
        <td style={{ textAlign: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {getPaymentTypeIcon(item.order?.payment_type)}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link title={item.inventory ? 'Available in inventory' : ''} className="orders-page-item-link" to={`/order/${item.order?.order_number}`}
                style={{ margin: '0 5px', background: '#fff', padding: '3px', borderRadius: '4px', position: 'relative', zIndex: 111 }}>
                <img src={`${item.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                <span
                  style={{
                    position: 'absolute',
                    top: '-7px',
                    right: '-7px',
                    lineHeight: '15px',
                    background: '#fe7f4a',
                    borderRadius: '100px',
                    width: '15px',
                    height: '15px',
                    textAlign: 'center',
                    fontSize: '12px',
                    color: '#fff',
                    fontWeight: 400,
                  }}
                >{item.quantity}</span>
                {item.inventory ?
                  <span style={{
                    position: 'absolute',
                    bottom: '-7px',
                    right: '-7px',
                  }}><WarehouseOutlinedIcon style={{ fontSize: '15px', color: '#000' }} /></span> : ''
                }
              </Link>
            </div>
          </div>
        </td>
        <td>
          <Link to={`/order/${item.order?.order_number}`} style={{ color: '#5a5858' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${item.country?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${item.country?.toLowerCase()}.png 2x`}
                alt=""
              />
              <span className='Ip-title' style={{ margin: '0px 5px', color: !item.order?.status && 'tomato' }}> {item.title}</span>
            </div>
          </Link>
        </td>

        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.order?.status ?
              <div>
                {item.currency}  {Number(item.price)}
              </div>
              :
              <span
                className="alert alert-danger boot-alert-style">
                {item.currency}  {Number(item.price)}
              </span>
            }
          </div>
        </td>
        <td>
          <div className="dFlex center nowrap jcr">
            {item.order?.checkout === 'failed' ? <span className='badge badge-danger'>Payment Failed</span> :
              <>
                {getThisStatus(item.state, item.shipping_state, item.order?.states)}
              </>
            }
            <div style={{ margin: '0px 5px' }}>
              <span
                className="alert boot-alert-style" style={{ color: !item.order?.status && 'tomato' }}>
                {item.order?.country}
              </span>
            </div>
          </div>
        </td>
        <td>
          <div style={{ position: 'relative', zIndex: 111, color: !item.order?.status && 'tomato' }}>{item.order?.domain}</div>
        </td>
        <td className="sticky-column" style={{background: tableBgColor(item.state, item.shipping_state, item.order?.states)}}>
          <div className="dFlex center nowrap jcr" style={{ justifyContent: 'right', color: !item.order?.status && 'tomato' }}>
            {moment(item?.createdAt).format('llll')}
          </div>
        </td>
      </tr>
    );
  };


  return (
    <>
      {ordersData?.entities?.undefined?.order_items?.map(function (item, index) {
        return <ComponentToPrintWrapper key={index} item={item} />;
      })}
    </>
  );
}

export default OrderItemsList;
